<template>
  <div class="about-eclipse">
    <div class="about-eclipse-inner">
      <p>
        <span class="big-text">{{text.title[lang]}}</span>
        {{text.leadText[lang]}}
      </p>

      <div class="subtitle">
        <div class="penumbra-circle"></div>
        <div>{{text.penumbraTitle[lang]}}</div>
      </div>

      <p>{{text.penumbraText[lang]}}</p>

      <div class="subtitle">
        <div class="umbra-circle"></div>
        <div>{{text.ubmbraTitle[lang]}}</div>
      </div>

      <p>{{text.ubmbraText[lang]}}</p>

      <div v-if="isSp" class="sub-window-container">
        <SubWindow :activeMin="activeMin" :lang="lang"/>
      </div>

      <div class="btns">
        <div class="close-btn">
          <button class="moon-btn" @click="clickCloseBtn">{{text.closeBtnText[lang]}}</button>
        </div>
        <div class="lang-btn">
          <button class="moon-btn" @click="changeLang">
            {{text.languageBtnText[lang]}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import SubWindow from "./SubWindow.vue";
  import { toRef, defineProps, defineEmits, computed } from "vue";
  const props = defineProps({ activeMin: String, lang: String });
  const activeMin = toRef(props, "activeMin");
  const emit = defineEmits(["clickCloseBtn", "changeLang"]);
  const isSp = computed(() => { return window.innerWidth < 700; });
  const text = {
    title: { 
      ja:"月食とは",
      en:"A lunar eclipse"
    },
    leadText: {
      ja: "、地球の影に月が入ることによって月が欠けて見える現象です。月の満ち欠けとは異なる現象で数時間程度で変動します。 太陽の光によってできた地球の影には「本影」とそれを取り囲む「半影」の2種類があり、月がどちらの影に入り込むかによって、月食の種類が変わります。",
      en: " is a phenomenon in which total or part of the moon is disappeared with the shadows of the earth. This phenomenon is different from the phase of the moon, and it changes over a period of a few hours. There are two types of shadows of the earth created by the sun's rays: \"umbra\" (the dark center portion of a shadow) and \"penumbra\" (the faint outer part of a shadow), depending on which shadow the moon enters."
    },
    penumbraTitle: {
      ja: "半影食",
      en: "Penumbral eclipse"
    },
    penumbraText: {
      ja: "薄い半影に、月の一部または全部が入った状態です。目で見ただけでは月が欠けているかどうか、はっきりしません。",
      en: "This is a condition in which part or all of the moon is in the faint outer part of the shadow. It is not clear whether the moon is shaded or not just by looking at it with eyes."
    },
    ubmbraTitle: {
      ja: "本影食",
      en: "Umbral eclipse"
    },
    ubmbraText: {
      ja: "濃い本影に、月の一部または全部が本影に入った状態です。一般的に「月食」というと、「本影食」のことを指し、実際に月が欠けて見えます。月の一部だけが本影に入り込む現象が「部分月食」、月の全てが本影に入り込む現象が「皆既月食」です。皆既月食の月は赤っぽい普段とは全く違った色になります。11月8日の月食は、「皆既月食」です。",
      en: "A lunar eclipse occurs when part or all of the moon is in the dark center portion of a shadow. Generally speaking, a \"lunar eclipse\" refers to a \"Umbral eclipse,\" in which the Moon is actually seen as shaded. A partial lunar eclipse is when only part of the moon is in the umbra, and a total lunar eclipse is when the entire moon is in the umbra. The lunar eclipse on November 8 will be a \"total lunar eclipse.\""
    },
    closeBtnText: {
      ja: "閉じる",
      en: "Close"
    },
    languageBtnText: {
      ja: "English",
      en: "日本語"
    }
  };
  const clickCloseBtn = () => {
    emit("clickCloseBtn", false);
  };
  const changeLang = () => {
    emit("changeLang");
  };

</script>

<style lang="scss" scoped>
@import "@/style/globalStyle.scss";
.about-eclipse {
    z-index: 20;
    position: absolute;
    pointer-events: none;
    left: 32px;
    bottom: 230px;
    @include sp {
      display: flex;
      justify-content: center;
      width: 100%;
      max-height: calc(100% - 160px);
      margin-top: 20px;
      left: initial;
      bottom: initial;
    }

  &-inner {
    background: #000000ee;
    color: #ccc;
    font-size: 13px;
    line-height: 1.5rem;
    width: 86%;
    max-width: 640px;
    overflow-y: scroll;
    padding: 10px 14px;
    border: solid 2px var(--compass-moon-color-primarily);
    pointer-events: initial;

    .big-text {
      font-size: 20px;
    }
    .subtitle{
      display: flex;
      align-items: center;
      font-size: 14px;
      & + p {
        margin-top: 0;
      }
      & > div {
        margin-right: 5px;
      }
      .umbra-circle {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        background-color: #784B3D;
      }
      .penumbra-circle {
        display: flex;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: #72737C;
      }
    }

    .sub-window-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    .btns {
      display: flex;
      justify-content: space-between;    
    }
    .lang-btn {
      text-align: right;
    }
    .close-btn {
      button {
        width: 80px;
      }
    }
  }
}

</style>
