<template>
  <div class="goldframe">
    <div id="top-border" />
    <div id="bottom-border" />
    <div id="left-border" />
    <div id="right-border" />
  </div>
</template>

<style>
#top-border,
#bottom-border,
#left-border,
#right-border {
  z-index: 10;
  background: #a1a4ab;
  position: fixed;
}
#top-border {
  top: 0;
}
#bottom-border {
  bottom: 0;
}
#left-border {
  left: 0;
}
#right-border {
  right: 0;
}
#left-border,
#right-border {
  top: 0;
  bottom: 0;
  width: 8px;
}
#top-border,
#bottom-border {
  left: 0;
  right: 0;
  height: 8px;
}
</style>
