<template>
  <transition>
    <div v-if="isVisible">
      <EclipseAbout
        v-if="isShowEclipseAbout"
        :activeMin="eclipseTime[activeMin]"
        :lang="lang"
        @clickCloseBtn="isShowEclipseAbout = false"
        @changeLang="changeLang"
      />
      <div
        class="open-howto-btn"
        @click="isShowEclipseAbout = !isShowEclipseAbout"
      >
        <button class="moon-btn">{{ textList.aboutBtn[lang] }}</button>
      </div>
      <SubWindow
        class="sub-window-pc"
        :activeMin="eclipseTime[activeMin]"
        :lang="lang"
      />
      <div class="timelapse-container">
        <div class="timelapse-contents">
          <!-- <input type="image" src="" /> -->
          <div class="dt-container">
            <span class="date">11/8</span>
            <span class="time"
              >{{ activeMinStr }}<span class="time-zone">JST</span></span
            >
          </div>
          <label id="mins"></label>
          <div class="slidebar-area">
            <div class="slidebar">
              <input
                v-model="activeMin"
                @input="onChangeMin"
                type="range"
                min="0"
                max="356"
                step="1"
                :style="rangeGradient()"
              />
              <div class="shadow" :style="shadowWidth()"></div>
              <div class="scale"><div class="dot-line"></div></div>
              <div
                class="current-time"
                :style="barPositon()"
                v-if="0 <= realMin && realMin <= 356"
              >
                <span>現在</span>
                <div class="bar"></div>
              </div>
              <div class="scales-str">
                <span>17:00</span>
                <span>19:00</span>
                <span>21:00</span>
                <span>22:56</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import SubWindow from "./SubWindow.vue";
import EclipseAbout from "./EclipseAbout.vue";
import { ref, defineEmits, defineProps, computed, toRefs } from "vue";
import eclipseTime from "../../../assets/eclipse_time_series.json";
const props = defineProps({ isVisible: Boolean });
const { isVisible } = toRefs(props);
const isShowEclipseAbout = ref(false);

const emit = defineEmits(["on-change-time"]);
const onChangeMin = () => {
  emit("on-change-time", eclipseTime[activeMin.value]);
};
const activeMinStr = computed(() => {
  return eclipseTime[activeMin.value].split(" ")[1];
});

const now = new Date(); // 現在時刻を取得
const startDt = new Date(2022, 10, 8, 17, 0);
const diff = now.getTime() - startDt.getTime();
const min = Math.floor(diff / 1000 / 60);

const activeMin = ref(0);
const realMin = ref(min);

const reflectRealTime = () => {
  const now = new Date(new Date().toLocaleString({ timeZone: "Asia/Tokyo" }));
  const startDt = new Date(2022, 10, 8, 17, 0);
  const diff = now.getTime() - startDt.getTime();
  realMin.value = Math.floor(diff / 1000 / 60);
};

if (0 <= realMin.value && realMin.value <= 356) {
  activeMin.value = min;
}

if (realMin.value <= 356) {
  reflectRealTime();
  setInterval(reflectRealTime, 2000);
}

const rangeGradient = () => {
  const progress = activeMin.value / 356;
  return `background: linear-gradient(
    to right,
    #181B31 0%,
    #181B31 ${progress * 100}%,
    #BBC1CD ${progress * 100}%,
    #BBC1CD 100%
  );`;
};

const shadowWidth = () => {
  const progress = activeMin.value / 356;
  return `width:${progress * 400}px;`;
};

const barPositon = () => {
  const progress = realMin.value / 356;
  return `left:${progress * 386 - 3}px;`;
};

const lang = ref("ja");
const changeLang = () => {
  lang.value = lang.value == "ja" ? "en" : "ja";
};
const textList = ref({
  aboutBtn: {
    ja: "月食とは？",
    en: "What's Eclipse?",
  },
});
</script>

<style lang="scss" scoped>
@import "@/style/globalStyle.scss";
.open-howto-btn {
  z-index: 1;
  position: absolute;
  left: 33px;
  bottom: 230px;
  @include sp {
    z-index: 10;
    left: initial;
    right: 5px;
    bottom: 100px;
  }
  button {
    min-width: 100px;
    height: 30px;
    @include sp {
      background: #181b31;
    }
  }
}

@include sp {
  .sub-window-pc {
    display: none;
  }
}

.timelapse-container {
  z-index: 1;
  position: fixed;
  background: #a1a4abe0;
  width: 100%;
  max-width: 830px;
  height: 86px;
  bottom: 0;
  left: 0;
  padding: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  @include tablet {
    width: auto;
    max-width: initial;
    justify-content: start;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 44px;
    padding-left: 240px;
  }
  @include sp {
    width: 100%;
    max-width: initial;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    padding: 0px;
    padding-bottom: 50px;
  }
  .timelapse-contents {
    display: flex;
    @include tablet {
      width: 100%;
      flex-direction: column;
      padding: 10px 20px;
      margin-left: 10px;
    }
    .slidebar-area {
      margin-left: 35px;
      margin-right: 30px;
      padding-top: 22px;
      @include tablet {
        margin-left: 0;
        padding-top: 0;
      }
      @include sp {
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
      }
      .slidebar {
        width: 400px;
        position: relative;
        @include sp {
          width: 100%;
        }
        input {
          position: absolute;
          width: 400px;
          z-index: 2;
          @include sp {
            width: 100%;
          }
        }
        .shadow {
          background: #01030a13;
          position: absolute;
          top: 6px;
          left: 2px;
          height: 5px;
          @include sp {
            display: none; /** スマホでは表示しない */
          }
        }
        .scales-str {
          display: flex;
          position: absolute;
          justify-content: space-between;
          width: 430px;
          top: 10px;
          left: -13px;
          // margin: 0 -6px;
          @include sp {
            width: calc(100% + 26px); /** テキストの位置調整 26px */
          }
          span {
            font-weight: 700;
          }
        }
        .current-time {
          top: -30px;
          left: 22px;
          @include sp {
            display: none;
          }
          @include tablet {
            display: none;
          }
          .bar {
            width: 3px;
            height: 22px;
            background: #181b31;
          }
          span {
            width: 24px;
          }
          pointer-events: none; /* 追加 */
          position: absolute;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          z-index: 2;
        }
        .scale {
          position: absolute;
          width: 410px;
          top: 4px;
          left: 2px;
          @include sp {
            width: 100%;
          }
          .dot-line {
            height: 6px;
            background-image: linear-gradient(
              to right,
              #23242688,
              #23242688 1px,
              transparent 1px,
              transparent 19px
            );
            background-size: 19px 6px;
            background-position: left bottom;
            background-repeat: repeat-x;
          }
        }
      }
    }
  }
}

.dt-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-bottom: 0.5rem;
  .date {
    font-size: 1rem;
    font-weight: 400;
  }
  .time {
    .time-zone {
      margin-left: 3px;
      font-size: 0.8rem;
      font-weight: 400;
    }
    margin-top: 3px;
    font-size: 2rem;
    font-weight: 700;
  }
}

.map-overlay .map-overlay-inner {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
}

.map-overlay h2 {
  line-height: 24px;
  display: block;
  margin: 0 0 10px;
}

.map-overlay .legend .bar {
  height: 10px;
  width: 100%;
  background: linear-gradient(to right, #fca107, #7f3121);
}

.map-overlay input {
  background-color: transparent;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0;
  cursor: ew-resize;
}

input[type="range"] {
  -webkit-appearance: none; /* これ無しだとスタイルがほぼ全く反映されないので注意 */
  appearance: none;
  cursor: pointer; /* カーソルを分かりやすく */
  outline: none; /* スライダーのアウトラインは見た目がキツイので消す */
  height: 4px; /* バーの高さ */
  width: 100%; /* バーの幅 */
  background: #bbc1cd; /* バーの背景色 */
  border-radius: 100px; /* バーの両端の丸み */
}
/* WebKit向けのつまみ */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /*  🚩デフォルトのつまみのスタイルを解除 */
  background: #181b31; /* 背景色 */
  width: 14px; /* 幅 */
  height: 14px; /* 高さ */
  border-radius: 50%; /* 円形に */
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15); /* 影 */
}
/* Moz向けのつまみ */
input[type="range"]::-moz-range-thumb {
  background: #fff; /* 背景色 */
  width: 14px; /* 幅 */
  height: 14px; /* 高さ */
  border-radius: 50%; /* 円形に */
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15); /* 影 */
  border: none; /* デフォルトの線を消す */
}
/* Firefoxで点線が周りに表示されてしまう問題の解消 */
input[type="range"]::-moz-focus-outer {
  border: 0;
}
</style>
