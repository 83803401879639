<script setup>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// geocoder
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
// directions
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
import { onMounted, ref } from "vue";
import MainMapToolbar from "./MainMapToolbar";
import GoldFrame from "./Common/GoldFrame.vue";
import EclipseContents from "./EventContents/Eclipse/EclipseContents.vue";
import { useOperation } from "./useOperation";
import { use2D3DMode } from "./use2D3DMode";
import COMMON_CONST from "../js/common-const.js";
import { useMainMode } from "./useMainMode";
import MessagePopup from "./Common/MessagePopup.vue";

const map = ref({});
const { is2D3DMode, switch2D3DMode } = use2D3DMode(map);
const { selectedMode, changeMode } = useMainMode(map);

// URLを取得
let url = new URL(window.location.href);

// URLSearchParamsオブジェクトを取得
let params = url.searchParams;
let lat = params.get("lat");
let lng = params.get("lng");
let INIT_LNGLAT = [lng, lat];

onMounted(() => {
  mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

  const initialZoom = window.innerWidth > 700 ? 1.5 : 0.8;

  map.value = new mapboxgl.Map({
    container: "map",
    style: {
      version: 8,
      sources: {
        m_streets: {
          type: "raster",
          tiles: [
            "https://assets-compass-tenchijin-moon.s3.ap-northeast-1.amazonaws.com/Lunar_tile07/{z}/{x}/{y}.png",
          ],
          tileSize: 128,
          attribution:
            '\
             <a href="https://astrogeology.usgs.gov/search/map/Moon/LRO/LROC_WAC/Lunar_LRO_LROC-WAC_Mosaic_global_100m_June2013/" target="_blank">NASA/USGS</a> \
             ',
        },
      },
      layers: [
        {
          id: "mierunemap",
          type: "raster",
          source: "m_streets",
        },
      ],
    },
    zoom: initialZoom,
    center: INIT_LNGLAT,
    parallels: [85, 90],
    projection: "globe", // default is set to be globe.
  });

  if (lat != null && lng != null) {
    const el = document.createElement("div");
    el.className = "marker";
    const width = 65;
    const height = 58;
    el.style.backgroundImage = "url(marker.png)";
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.top = `5px`;
    el.style.left = `20px`;
    el.style.backgroundSize = "100%";
    new mapboxgl.Marker(el).setLngLat(INIT_LNGLAT).addTo(map.value);
  }

  const secondsPerRevolution = 180; // At low zooms, complete a revolution every two minutes.
  const maxSpinZoom = 5; // Above zoom level 5, do not rotate.
  const slowSpinZoom = 3; // Rotate at intermediate speeds between zoom levels 3 and 5.

  let userInteracting = false;
  let spinEnabled = false;

  async function spinGlobe() {
    const zoom = map.value.getZoom();
    if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
      let distancePerSecond = 360 / secondsPerRevolution;
      if (zoom > slowSpinZoom) {
        // Slow spinning at higher zooms
        const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
        distancePerSecond *= zoomDif;
      }
      const center = map.value.getCenter();
      center.lng -= distancePerSecond;
      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      await map.value.easeTo({ center, duration: 1000, easing: (n) => n });
    }
  }

  map.value.on("style.load", () => {
    map.value.setFog({
      color: "rgb(186, 210, 235)", // Lower atmosphere
      "high-color": "rgb(36, 92, 223)", // Upper atmosphere
      "horizon-blend": 0.02, // Atmosphere thickness (default 0.2 at low zooms)
      "space-color": "rgb(11, 11, 25)", // Background color
      "star-intensity": 0.6, // Background star brightness (default 0.35 at low zoooms )
    });
  });

  map.value.on("load", () => {
    const date_now = new Date().toLocaleString();

    let filterMin = ["==", "date", getTimeStr()];
    function getTimeStr() {
      const d = new Date(new Date().toLocaleString({ timeZone: "Asia/Tokyo" }));
      const yyyy = d.getFullYear();
      const MM = ("0" + (d.getMonth() + 1)).slice(-2);
      const dd = ("0" + d.getDate()).slice(-2);
      const hh = ("0" + d.getHours()).slice(-2);
      const mm = ("0" + d.getMinutes()).slice(-2);
      return yyyy + "/" + MM + "/" + dd + " " + hh + ":" + mm;
    }

    console.log(date_now);

    // penunbra layer
    map.value.addSource("penumbra", {
      type: "geojson",
      data: "./geojson/eclipse_penumbra.geojson",
    });

    map.value.addLayer({
      id: "penumbra",
      type: "fill",
      source: "penumbra", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#000000", // 黒
        "fill-opacity": 0.5,
      },
      filter: ["all", filterMin],
    });

    // umbra layer
    map.value.addSource("umbra", {
      type: "geojson",
      data: "./geojson/eclipse_umbra.geojson",
    });

    map.value.addLayer({
      id: "umbra",
      type: "fill",
      source: "umbra", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#cf4500", // 赤銅色
        "fill-opacity": 0.3,
      },
      filter: ["all", filterMin],
    });

    map.value.resize();
  });

  map.value.on("moveend", () => {
    spinGlobe();
  });

  // Pause spinning on interaction
  map.value.on("mousedown", () => {
    userInteracting = true;
  });

  // Restart spinning the globe when interaction is complete
  map.value.on("mouseup", () => {
    userInteracting = false;
    spinGlobe();
  });

  document.getElementById("btn-spin").addEventListener("click", (event) => {
    spinEnabled = !spinEnabled;
    if (spinEnabled) {
      spinGlobe();
    } else {
      map.value.stop(); // Immediately end ongoing animation
    }
  });

  spinGlobe();

  document.getElementById("fly").addEventListener("click", () => {
    // Fly to a random location by offsetting the point -74.50, 40
    // by up to 5 degrees.
    map.value.flyTo({
      center: [0, 0],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  });
});
const { selectedOperation, changeOperation } = useOperation(map);

const filterMaine = (eclipseTime) => {
  const filterMin = ["==", "date", eclipseTime];
  map.value.setFilter("umbra", ["all", filterMin]);
  map.value.setFilter("penumbra", ["all", filterMin]);
};
</script>

<script type="text/javascript">
function returnToEarth() {
  document.location = "https://tenchijin.co.jp/compass/";
}
</script>

<template>
  <!-- マップをここに表示している -->
  <div id="map" class="map">
    <div class="overlap-ui">
      <GoldFrame></GoldFrame>
      <div class="moon-logo">
        <img width="260" height="40.75" src="tcj-logo.svg" />
      </div>
      <MainMapToolbar
        class="toolbar"
        @on-change-operation="changeOperation"
        @on-change-mode="changeMode"
        :selectedOperation="selectedOperation"
        :selectedMode="selectedMode"
      />
      <MessagePopup
        class="message-popup"
        :selectedOperation="selectedOperation"
      ></MessagePopup>
      <div class="bottom-left-buttons">
        <div class="button-row">
          <button type="button" id="fly">
            <img src="../../public/img/icons/toolbar/icon-center.svg" />
          </button>
          <button type="button" id="btn-spin">
            <img src="../../public/img/icons/toolbar/icon-spin.svg" />
          </button>
          <button type="button" @click="switch2D3DMode" id="btn-2d3d">
            <img
              v-if="is2D3DMode"
              src="../../public/img/icons/toolbar/2d3d-2d.svg"
            />
            <img v-else src="../../public/img/icons/toolbar/2d3d-3d.svg" />
          </button>
        </div>
      </div>
      <EclipseContents
        class="eclipse-contents"
        :isVisible="selectedMode === COMMON_CONST.MAP_MODE.ECLIPSE"
        @on-change-time="filterMaine"
      />
    </div>
    <div class="return-earth-button" @click="returnToEarth">
      <span>Back To Earth</span>
    </div>
  </div>
  <!-- <div id="distance" class="distance-container"></div> -->
</template>

<style lang="scss" scoped>
@import "@/style/globalStyle.scss";
.map {
  font-family: "Roboto", "Noto Sans", sans-serif;
  height: 100%;
  @include sp {
    height: calc(100% - 62px); /* toolbarの70px - フレームの8px  */
  }
  .overlap-ui {
    display: block;
    .toolbar {
      z-index: 3;
      @include sp {
        z-index: 100;
        width: 100%;
        top: initial;
        left: 0;
        height: 70px;
        bottom: 0;
        border-top: 1px solid var(--compass-moon-color-primarily);
        justify-content: center;
      }
    }
    .toolbar-right {
      z-index: 3;
      top: 2%;
      right: 100px;
    }
    .eclipse-contents {
      z-index: 3;
      display: block;
    }
    .moon-logo {
      position: fixed;
      top: 28px;
      right: 3%;
      z-index: 3;
      img {
        width: 260px;
        height: 40.75px;
      }
      @include sp {
        right: initial;
        left: 0px;
        top: 0px;
        margin-top: 16px;
        margin-left: 20px;
        img {
          width: 200px;
        }
      }
    }
    .bottom-left-buttons {
      position: fixed;
      width: 173px;
      bottom: 90px;
      right: 24px;
      z-index: 3;
      @include tablet {
        width: initial;
        height: 172px;
        top: 50%;
        margin-top: -86px;
        right: 12px;
      }
      .button-row {
        display: flex;
        justify-content: space-between;
        @include tablet {
          flex-direction: column;
          height: 100%;
        }
        button {
          height: 50px;
          width: 50px;
          background: transparent;
          color: #000;
          border: none;
          border-radius: 10px;
        }
        button:hover {
          opacity: 0.7;
        }

        #fly {
          padding: 0;
        }
        #btn-spin {
          padding: 0;
        }
        #btn-2d3d {
          overflow: hidden;
          padding: 0;
        }
      }
    }
    .timer-tooltip {
      position: fixed;
      top: 15px;
      left: 240px;
      @include sp {
        position: relative;
        top: 80px;
        width: 100%;
        max-width: 300px;
        left: initial;
        margin: auto;
      }
    }
  }
  .return-earth-button {
    bottom: 38px;
    right: 24px;
    position: fixed;
    display: flex;
    width: 169px;
    height: 32px;
    border: 2px solid #a1a4ab;
    z-index: 3;
    background: transparent;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include tablet {
      bottom: initial;
      top: 64px;
      right: 0;
      margin: 20px 20px;
      width: 100px;
    }
    @include sp {
      bottom: initial;
      top: 0;
      right: 0;
      margin: 20px 20px;
      width: 80px;
      font-size: 10px;
    }
    span {
      color: #a1a4ab;
    }
  }
}

.distance-container {
  position: absolute;
  background-color: white;
  color: black;
  top: 30%;
  left: 10px;
  z-index: 1;
  font-size: 25px;
  margin: 0;
  padding: 2px 10px;
  border-radius: 3px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#btn-eclipse-mode:hover {
  background-color: gold;
}
</style>
