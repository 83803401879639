import COMMON_CONST from "../js/common-const.js";
import { ref } from "vue";
const MAP_MODE = COMMON_CONST.MAP_MODE;

export const useMainMode = (map) => {
  const selectedMode = ref(COMMON_CONST.MAP_MODE.NONE);

  const changeMode = (mode) => {
    // モード選択無しの場合、"off()"がない為スキップ
    if (selectedMode.value !== COMMON_CONST.MAP_MODE.NONE) {
      onSwitchFunction[selectedMode.value].off();
    }

    // すでに選択されているmodeを選択した場合、選択が解除
    if (selectedMode.value === mode) {
      selectedMode.value = COMMON_CONST.MAP_MODE.NONE;
      return;
    }

    onSwitchFunction[mode].on();
    selectedMode.value = mode;
  };

  // 月食モードに変更した時の動作
  const switchEclipseMode = {
    on: () => {
      map.value.setLayoutProperty("umbra", "visibility", "visible");
      map.value.setLayoutProperty("penumbra", "visibility", "visible");
    },
    off: () => {
      map.value.setLayoutProperty("umbra", "visibility", "none");
      map.value.setLayoutProperty("penumbra", "visibility", "none");
    },
  };

  const onSwitchFunction = {
    [MAP_MODE.ECLIPSE]: switchEclipseMode,
  };

  return { selectedMode, changeMode };
};
