<template>
  <div class="sub-window">
    <div class="sub-window-inner">
      <div class="overlay-texts">
        <div class="overlay-texts__status">{{ statusText[lang] }}</div>
        <div class="overlay-texts__umbra">{{ textList.umbra[lang] }}</div>
        <div class="overlay-texts__penumbra">{{ textList.penumbra[lang] }}</div>
        <div class="overlay-texts__north"><span>N</span></div>
      </div>
      <div class="image-area">
        <LoadingMask v-if="isLoading" />
        <img :src="imgSrc" alt="月食状況" v-else />
      </div>
    </div>
    <div class="footer">
      <span>{{ textList.title[lang] }}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, ref, onMounted, computed } from "vue";
import eclipseTime from "../../../assets/eclipse_time_series.json";
import LoadingMask from "../../../components/Common/LoadingMask.vue";
import { imageCache } from "../../../js/image-cache";

// TODO:URLはCONST化する？
const domain =
  "https://assets-compass-tenchijin-moon.s3.ap-northeast-1.amazonaws.com/shadow_images_clip_nocomment_small/";

const props = defineProps({ activeMin: String, lang: String });
const activeMin = toRef(props, "activeMin");
const time2imageMap = ref({});
const isLoading = ref(true);
const imgSrc = computed(() => {
  const map = time2imageMap.value;
  return map[activeMin.value];
});

/** 月食の状態のテキストとこれを表示する時間 */
const statusList = [
  {
    ja: "半影食開始",
    en: "Penumbral Eclipse begins",
    start: "2022/11/08 17:00",
    end: "2022/11/08 17:05",
  },
  {
    ja: "部分食開始",
    en: "Partial Eclipse begins",
    start: "2022/11/08 18:08",
    end: "2022/11/08 18:13",
  },
  {
    ja: "皆既食開始",
    en: "Total Eclipse begins",
    start: "2022/11/08 19:16",
    end: "2022/11/08 19:21",
  },
  {
    ja: "食の最大",
    en: "Maximum Eclipse",
    start: "2022/11/08 19:59",
    end: "2022/11/08 20:04",
  },
  {
    ja: "皆既食終了",
    en: "Total Eclipse ends",
    start: "2022/11/08 20:42",
    end: "2022/11/08 20:47",
  },
  {
    ja: "部分食終了",
    en: "Partial Eclipse ends",
    start: "2022/11/08 21:49",
    end: "2022/11/08 21:54",
  },
  {
    ja: "半影食終了",
    en: "Penumbral Eclipse ends",
    start: "2022/11/08 22:52",
    end: "2022/11/08 22:57",
  },
];

/** スライダーの時間のインデックス番号が、startとendの時間のインデックス番号の間にはいっている場合、日英テキストのobjectを返す */
const statusText = computed(() => {
  const nowIndex = eclipseTime.indexOf(activeMin.value);
  const status = statusList.find((s) => {
    const startIndex = eclipseTime.indexOf(s.start);
    const endIndex = eclipseTime.indexOf(s.end);
    return startIndex <= nowIndex && nowIndex <= endIndex;
  });
  return status ? status : { ja: "", en: "" };
});

const textList = ref({
  title: { ja: "月と影の位置関係", en: "Position of the moon and shadows" },
  umbra: { ja: "本影", en: "Umbra" },
  penumbra: { ja: "半影", en: "Penumbra" },
});

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossorigin", "anonymous");
    img.onload = () => {
      const data = createDataUrl(img);
      return resolve(data);
    };
    img.onerror = (e) => reject(e);
    img.src = src;
  });
};

const createDataUrl = (img) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.height = img.height;
  canvas.width = img.width;
  ctx.drawImage(img, 0, 0);

  return canvas.toDataURL("image/png");
};

const formatUrl = (time) => {
  const formated = time.replaceAll("/", "").replace(" ", "").replace(":", "");
  return domain + formated + ".png";
};

const fetchAllImage = async () => {
  // cacheがない時
  if (imageCache.subWindow === null) {
    const tasks = eclipseTime.map((time) => {
      return loadImage(formatUrl(time));
    });
    const completionTasks = await Promise.all(tasks);

    completionTasks.forEach((image, idx) => {
      time2imageMap.value[eclipseTime[idx]] = image;
    });
    imageCache.subWindow = time2imageMap.value;
  } else {
    time2imageMap.value = imageCache.subWindow;
  }
};

onMounted(async () => {
  isLoading.value = true;
  await fetchAllImage();
  isLoading.value = false;
});
</script>

<style lang="scss" scoped>
@import "@/style/globalStyle.scss";
.sub-window {
  z-index: 2;
  position: fixed;
  left: 42px;
  bottom: 46px;
  @include sp {
    position: relative;
    left: initial;
    bottom: initial;
  }
  .sub-window-inner {
    position: relative;
    width: 200px; /** cf. 153px in figma */
    height: 160px;
    .overlay-texts {
      position: absolute;
      color: white;
      width: 100%;
      text-align: center;
      & > div {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 12px;
      }
      &__status {
        top: 2px;
      }
      &__umbra {
        top: 90px;
      }
      &__penumbra {
        top: 25px;
      }
      &__north {
        top: 0;
        justify-content: flex-end !important;
        span {
          background: #111326;
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }
    }
    .image-area {
      background: #111326;
      border-radius: 2px;
      padding: 4px;
      display: flex;
      justify-content: center;
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }
  .footer {
    display: flex;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background: #111326;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    span {
      font-weight: 400;
      color: #fff;
    }
  }
}
</style>
