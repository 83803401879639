import { ref, onMounted } from "vue";
export const use2D3DMode = (map) => {
  const is2D3DMode = ref(false);

  const switch2D3DMode = () => {
    is2D3DMode.value = !is2D3DMode.value;
    if (is2D3DMode.value) {
      map.value.setProjection("globe");
    } else {
      map.value.setProjection("mercator");
    }
  };
  onMounted(() => {
    // 初期状態はOff
    is2D3DMode.value = true;
  });
  return { is2D3DMode, switch2D3DMode };
};
