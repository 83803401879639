<template>
  <div class="message-popup" v-if="isShow">
    <div>
      {{text.altitudeText[lang]}}
      <button id='lang-btn' class="lang-btn" @click="changeLang">
        {{text.languageBtnText[lang]}}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, defineEmits, computed } from "vue";
import COMMON_CONST from "@/js/common-const.js";
const props = defineProps({ selectedOperation: String });
const emit = defineEmits(["clickCloseBtn", "changeLang"]);
const { selectedOperation } = toRefs(props);
const MAP_OPERATIONS = COMMON_CONST.MAP_OPERATIONS;
const isShow = computed(() => {
  return selectedOperation.value === MAP_OPERATIONS.AREA
        || selectedOperation.value === MAP_OPERATIONS.RULER
        || selectedOperation.value === MAP_OPERATIONS.ALTITUDE;
});
const lang = ref("ja");
const changeLang = () => {
  lang.value = lang.value == "ja" ? "en" : "ja";
};

const text ={
  languageBtnText: {
    ja: "English",
    en: "日本語"
  },
  altitudeText: {
    ja: "月面上をクリックで測定開始、ダブルクリックで終了します。\nハンドツールに切り替えて図形をダブルクリックすると結果を確認できます。",
    en: "Click on the lunar surface to start measurement, double-click to finish. \nSwitch to the hand tool and click on the shape to see the result."
  },
}
</script>

<style lang="scss" scoped>

.message-popup {
  z-index: 100;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  div {
    background: #a1a4abee;
    color: white;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: pre-wrap;
  }
  .lang-btn {
    text-align: right;
  }
  .btns {
    background: #a1a4abee;
    display: flex;
    justify-content: space-between;    
  }
  .lang-btn {
  font-size: 12px;
  color: white;
  background: none;
  border: solid 1px white;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10%;
  // border: solid 1px var(--compass-moon-color-primarily);
  cursor: pointer;
  user-select: none;
  @media (hover: hover) and (pointer: fine) {
    /** デバイスがhoverに対応しているときのみ*/
    &:hover {
      transition: background-color 0.2s;
      background: #ffffff22;
      // background: blue;
    }
  }
}
}
</style>