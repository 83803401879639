const MAP_OPERATIONS = {
  MARKER: "marker",
  MOVE: "move",
  RULER: "ruler",
  AREA: "area",
  ALTITUDE: "altitude",
};

const MAP_MODE = {
  NONE: "none",
  ECLIPSE: "eclipse",
};

// zoom変更時に使用するRuler機能のTooltip表示閾値
const RULER_TOOLTIP_ZOOM_THRESHOLD = 7;

export default {
  MAP_OPERATIONS,
  RULER_TOOLTIP_ZOOM_THRESHOLD,
  MAP_MODE,
};
