<template>
  <link href="Roboto-Black.ttf" ref="stylesheet">
  <div ref="content" class="area-popup-content">
    <div class="body">
      <div class="relative">
        <div class="absolute" id="basecanvas-container">
          <canvas id="baseCanvas" height="332" width="326"></canvas>
        </div>
        <div>
          <Carousel>
            <Slide v-for="chartID in 3" :key="chartID">
              <div class="carousel__item">
                <div id="canvas1-container" class="canvas1-container"></div>
                <div id="canvas2-container" class="canvas2-container"></div>
                <div class = "relative2" height="332" width="326">
                  <div id="canvas3-container" class="canvas3-container"></div>
                  <div class="absolute2">
                    <span class="rate">
                      <img src="フルマラソン.png" class="full-marathon">
                    </span>
                    <span class="rate2" id="rate2">
                      <img src="フルマラソン.png" class="full-marathon">
                    </span>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
    <dispose-button
      color="rgba(255,255,255,0.7)"
      class="dispose-button"
      @click="removeLayer"
    />
    <div class="image-area">
      <LoadingMask v-if="isLoading" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref, onMounted, onUnmounted, onBeforeUnmount } from "vue";
import { Chart as ChartJS } from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation';
import { fromUrl } from 'geotiff';
import * as turf from "@turf/turf";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { getParams, drawChart1, drawChart2, drawChart3, drawChart4, setFullMarathon } from './useGraphPopupContents.js'
import LoadingMask from "../Common/LoadingMask.vue";
import DisposeButton from "@/components/Common/DisposeButton.vue";

const props = defineProps({
  feature: Object,
  removeFunction: Function,
});

ChartJS.register(annotationPlugin); // use annotation Plugin for base line
const { feature } = toRefs(props);
const isLoading = ref(true);

const removeLayer = () => {
  props.removeFunction();
};

let chart1, chart2, chart3, chart4;
let baseDistance = turf.length(feature.value._data.geometry) * 0.272514; // 月の縮尺
// let line = turf.lineString([feature.value._data.geometry.coordinates[0], feature.value._data.geometry.coordinates[1]])
let DEVIDE = baseDistance.toFixed(0); // 座標間を何分割してプロットするか

function reduceArraySizeLogic(baseDistance){
  // 引いた距離の長さに応じて、配列の大きさを調整する
  // https://github.com/Tenchijin-Compass/compass_moon_3d/issues/72

  if (baseDistance <= 150) return 1;
  else if (baseDistance <= 700) return 5;
  else if (baseDistance <= 1500) return 10;
  else if (baseDistance <= 3750) return 25;
  else return 50;
}

async function readCOG2(lngLatArray, xOffset, yOffset, eleId, devide)  {
  let reduceLogic = reduceArraySizeLogic(DEVIDE);
  let altitudeArray = []; // 標高データの配列 
  const filepath = 
    "https://assets-compass-tenchijin-moon.s3.ap-northeast-1.amazonaws.com/COG_Lunar_LRO_LOLA_Global_LDEM_118m_Mar2014.tif"
  const RESOLUTION = 118.45 // 1ピクセルの分解能(xy座標)

  const tiff = await fromUrl ( filepath ); 
  // const image = await tiff.getImage(0);
  // console.log(`Bounding box: ${image.getBoundingBox()}`);
  // console.log(`Origin: ${ image.getOrigin()}`);
  // console.log(`Resolution: ${image.getResolution()}`);
  // console.log(`Size: ${image.getWidth()}, ${image.getHeight()}`);
  // console.log(`Tile size: ${image.getTileWidth()}, ${image.getTileHeight()}`);
  // console.log(`Band: ${image.getSamplesPerPixel()}`);
  // console.log(lngLatArray);
  // console.log(lngLatArray[0][0], lngLatArray[0][1]); // (lng, lat)
  
  for (let i = 0; i < devide; i++) { 
    if (i%reduceLogic==0) {
      const pixel_coordinate_x = 46080/180 * (lngLatArray[eleId][0] + xOffset*i); // [-46080, 46080]
      const pixel_coordinate_y = 46080/180 * (lngLatArray[eleId][1] + yOffset*i); // [-23040, 23040]
      const image = await tiff.readRasters({
        bbox: [
          RESOLUTION*pixel_coordinate_x,
          RESOLUTION*pixel_coordinate_y,
          RESOLUTION*(pixel_coordinate_x+1),
          RESOLUTION*(pixel_coordinate_y-1)
        ],
      })
      altitudeArray[altitudeArray.length] = image[0][0]*0.5;
    } else {
      continue
    }
  }
  return altitudeArray
}

// async function readCOG(lngLatArray, xOffset, yOffset)  {
//   let altitudeArray = []; // 標高データの配列 
//   const filepath = 
//     "https://assets-compass-tenchijin-moon.s3.ap-northeast-1.amazonaws.com/COG_Lunar_LRO_LOLA_Global_LDEM_118m_Mar2014.tif"
//   const RESOLUTION = 118.45 // 1ピクセルの分解能(xy座標)

//   const tiff = await fromUrl ( filepath ); 
//   // const image = await tiff.getImage(0);
//   // console.log(`Bounding box: ${image.getBoundingBox()}`);
//   // console.log(`Origin: ${ image.getOrigin()}`);
//   // console.log(`Resolution: ${image.getResolution()}`);
//   // console.log(`Size: ${image.getWidth()}, ${image.getHeight()}`);
//   // console.log(`Tile size: ${image.getTileWidth()}, ${image.getTileHeight()}`);
//   // console.log(`Band: ${image.getSamplesPerPixel()}`);
//   // console.log(lngLatArray);
//   // console.log(lngLatArray[0][0], lngLatArray[0][1]); // (lng, lat)
  
//   for (let i = 0; i < DEVIDE; i++) { 
//     const pixel_coordinate_x = 46080/180 * (lngLatArray[0][0] + xOffset*i); // [-46080, 46080]
//     const pixel_coordinate_y = 46080/180 * (lngLatArray[0][1] + yOffset*i); // [-23040, 23040]
//     const image = await tiff.readRasters({
//       bbox: [
//         RESOLUTION*pixel_coordinate_x,
//         RESOLUTION*pixel_coordinate_y,
//         RESOLUTION*(pixel_coordinate_x+1),
//         RESOLUTION*(pixel_coordinate_y-1)
//       ],
//     })
//     altitudeArray[altitudeArray.length] = image[0][0]*0.5;
//   }
//   return altitudeArray
// }

async function createAltitudeArray(lngLatArray) {
  // console.log("createAltitudeArray function called with: ", lngLatArray);
  let allData =[]
  for (let i = 1; i < lngLatArray.length; i++) { 
    let line = turf.lineString([feature.value._data.geometry.coordinates[i-1], feature.value._data.geometry.coordinates[i]]);
    let devide = turf.length(line).toFixed(0) * 0.272514;
    let xOffset = (lngLatArray[i][0] - lngLatArray[i-1][0]) / devide;
    let yOffset = (lngLatArray[i][1] - lngLatArray[i-1][1]) / devide;

    const data = await readCOG2(lngLatArray, xOffset, yOffset, i-1, devide);
    allData = allData.concat(data)
  }
  return allData;
}

onMounted(async() => {
  // console.log("---chart1:",chart1,"----");

  isLoading.value = true;
  const data = await createAltitudeArray(feature.value._data.geometry.coordinates); 
  isLoading.value = false; 
  const params = getParams(feature, data);
  // console.log("data",data);
  // console.log("params",params);
  // console.log("start creating chart.");
  // console.log("draw chart1");
  chart1 = drawChart1("canvas1-container", 326, 332, data, params);
  // console.log("draw chart2");
  chart2 = drawChart2("canvas2-container", 326, 332, data, params);
  // console.log("draw chart3");
  chart3 = drawChart3("canvas3-container", 326, 332, data, params);
  // console.log("draw chart4");
  chart4 = drawChart4("basecanvas-container", 326, 332, data, params);
  setFullMarathon(params);
});

onBeforeUnmount(()=>{
  // console.log("before unmount");
});
onUnmounted(()=>{
  // console.log("unmount");
});
</script>

<style lang="scss" scoped>
.area-popup-content {
  .body {
    .row {
      display: flex;
      line-height: 1.8em;
      // border-top: 1px solid black;
      // border-top: 1px solid #a1a4ab;
      width: 100%;
      padding: 0px 0px;
      &:first-child {
        border-top: none;
      }
      &-title {
        font-size: 12px;
        color: #556fab;
        min-width: 90px;
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
      &-value {
        display: flex;
        align-items: center;
        color: #000;
        font-size: 12px;
        line-height: 1.5em;
      }
    }
  }
  .dispose-button {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 7px;
    bottom: 7px;
    white-space: normal;
    &:hover {
      opacity: 0.8;
    }
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  top: 0px;
  left: -2.7px;
}

.title-abtolute {
  position: absolute;
  top: 0px;
  left: 16px;
  font-size: 12px;
  color: rgba(255,255,255,0.7);
}

.relative2 {
  position: relative;
}

.absolute2 {
  position: absolute;
  top: 15%;
  left: 10%;
}

.canvas1-container {
  width: 326px;
  height: 332px;
}
.canvas2-container {
  width: 326px;
  height: 332px;
}
.canvas3-container {
  width: 326px;
  height: 332px;
}

.rate {
  position: relative;
  top: 15%;
  left: 10%;
  opacity: 0.2;
}

.rate2{
  position: absolute;
  left: 10%;
  width: 0%;
  overflow: hidden;
}

.full-marathon {
  width: 230px;
  height: 23px;
  // margin-left: 3%;
}
</style>