<template>
  <div ref="content" class="area-popup-content">
    <div class="body">
      <div class="line">
        <span class="line-title">{{ "Perimeter" }}</span>
        <span class="line-value">{{ calcDistance(feature) + " km" }}</span>
      </div>
      <div class="line">
        <span class="line-title">{{ "Area" }}</span>
        <span class="line-value">{{ calcArea(feature) + " km2" }}</span>
      </div>
    </div>
    <dispose-button
      color="rgba(255,255,255,0.7)"
      class="dispose-button"
      @click="removeLayer"
    />
  </div>
</template>

<script setup>
import DisposeButton from "@/components/Common/DisposeButton.vue";
import { defineProps, toRefs } from "vue";
import * as turf from "@turf/turf";

const props = defineProps({
  feature: Object,
  removeFunction: Function,
});

const { feature } = toRefs(props);

const calcArea = (feature) => {
  const area = turf.area(feature._data.geometry);
  return Math.round(((area * 0.272514 * 0.272514) / 1000000) * 100) / 100; // km^2
};

const calcDistance = (feature) => {
  const distance = turf.length(feature._data.geometry) * 0.272514; ///月の縮尺;
  return distance.toLocaleString();
};

const removeLayer = () => {
  props.removeFunction();
};
</script>

<style lang="scss" scoped>
.area-popup-content {
  .body {
    .line {
      display: flex;
      line-height: 1.8em;
      border-top: 1px solid #a1a4ab;
      width: 100%;
      padding: 8px 0px;
      &:first-child {
        border-top: none;
      }
      &-title {
        font-size: 12px;
        color: rgba(255, 229, 94, 1); // chugun黄色
        min-width: 90px;
        margin-left: 0%;
        display: flex;
        align-items: center;
      }
      &-value {
        display: flex;
        align-items: center;
        color: rgba(255, 229, 94, 1); // chugun黄色
        font-size: 12px;
        line-height: 1.5em;
      }
    }
  }
  .dispose-button {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 7px;
    bottom: 7px;
    color: rgba(255, 229, 94, 1); // chugun黄色;
    white-space: normal;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
