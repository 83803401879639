<template>
  <transition name="fade">
    <div class="loading">
      <div class="all-black" />
      <div class="loading-icon">
        <RadarSpinner :width="60" :height="60" />
      </div>
      <div class="slot">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import RadarSpinner from "@/components/Common/RadarSpinner.vue";
export default {
  components: {
    RadarSpinner,
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.loading {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.all-black {
  position: absolute;
  background-color: #000000;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.loading-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.slot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
