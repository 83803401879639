import { Chart as ChartJS } from 'chart.js/auto'
// import { drawPoint } from "chart.js/dist/helpers/helpers.canvas";
import * as turf from "@turf/turf";

export function getParams (feature, data) {
  const baseDistance = turf.length(feature.value._data.geometry) * 0.272514; // 月の縮尺
  const xlabel_array = getXLabel_array2(baseDistance);

  let altitude_top = Math.max(...data);
  let altitude_bottom = Math.min(...data);
  let altitude_total = altitude_top - altitude_bottom;
  let y_max = getAltitude(altitude_total);
  let lenOffset = 0;
  let maxTicksLimit = 0;
  
  // xLabel取得のロジック
  if(baseDistance<14){
    lenOffset = 1; // 100以下の時は固定で10
    maxTicksLimit = Math.floor((baseDistance/lenOffset)) + 1;
  } else if (baseDistance < 49){
    lenOffset = 5;
    maxTicksLimit = Math.floor((baseDistance/lenOffset)) + 1;
  } else if (baseDistance < 149){
    lenOffset = 10;
    maxTicksLimit = Math.floor((baseDistance/lenOffset)) + 1;
  } else if (baseDistance < 349){
    lenOffset = 25;
    maxTicksLimit = Math.floor((baseDistance/lenOffset)) + 1;
  } else if (baseDistance < 699){
    lenOffset = 50;
    maxTicksLimit = Math.floor((baseDistance/lenOffset)) + 1;
  } else if (baseDistance < 1499){
    lenOffset = 100;
    maxTicksLimit = Math.floor((baseDistance/lenOffset)) + 1;
  } else if (baseDistance < 5000) {
    lenOffset = 250;
    maxTicksLimit = Math.floor((baseDistance/lenOffset)) + 1;
  } else {
    lenOffset = 500;
    maxTicksLimit = Math.floor((baseDistance/lenOffset)) + 1;
  }

  console.log("distance: ", baseDistance.toFixed(2), "km, altitude", altitude_total, "m");

  const params = {
    baseDistance,
    xlabel_array,
    y_max,
    lenOffset,
    maxTicksLimit,
    altitude : {
      top: altitude_top,
      bottom: altitude_bottom,
      total: altitude_total,
    }
  };

  return params;
}

function getAltitude(altitude_total){
  if (altitude_total <= 1000){
    return 1000;
  } else if (altitude_total > 1000 && altitude_total <= 5000){
    return 5000;
  } else if (altitude_total > 5000 && altitude_total <= 10000){
    return 10000;
  } else {
    return 20000
  }
}

function reduceArraySizeLogic(baseDistance){
  // 引いた距離の長さに応じて、配列の大きさを調整する
  // https://github.com/Tenchijin-Compass/compass_moon_3d/issues/72

  if (baseDistance <= 150) return 1;
  else if (baseDistance <= 700) return 5;
  else if (baseDistance <= 1500) return 10;
  else if (baseDistance <= 3750) return 25;
  else return 50;
}

// function getXLabel_array (baseDistance) {
//   let xlabel_array = [];
//   const offset = baseDistance/(baseDistance.toFixed(0));
//   let xlabel = 0;
//   for(let i=0; i<baseDistance.toFixed(0); i++){
//     xlabel_array.push(xlabel.toFixed(0));
//     xlabel += offset;
//   }
//   return xlabel_array;
// }

function getXLabel_array2 (baseDistance) {
  // reduceLogicを利用して、中身を書き換える
  let reduceLogic = reduceArraySizeLogic(baseDistance);
  let xlabel_array = [];
  const offset = baseDistance/(baseDistance.toFixed(0));
  let xlabel = 0;
  for(let i=0; i<baseDistance.toFixed(0); i++){
    if (i%reduceLogic==0) {
      xlabel_array.push(xlabel.toFixed(0));
    }
    xlabel += offset;
  }
  return xlabel_array;
}


export function drawChart1 (containerId, width, height, data, params) {

  const context = getContext (containerId, width, height);

  /*  グリッドのみの描画 */
  return new ChartJS(context, {
    type: "line",
    data: {
      datasets: [{
        data: data,
        borderColor: 'rgb(255, 229, 94, 0)', // chugun黄色
        // borderColor: 'red',
        pointRadius: 0,
      }],
      labels: params.xlabel_array,
      pointRadius: 1,
    },
    options: {
      plugins: {
        title: {
          display: true,
          text: ["　Topographic Profile", ""],
          // text: ["", ""],
          color: 'rgba(255,255,255,0.7)',
          font: {
            size: 14
          }
        },
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          grid: {
            display: true,
            drawTicks: false,
            color: 'rgba(255,255,255,0.25)',
          },
          ticks: {
            color: "rgba(255,255,255,0.7)",
            maxTicksLimit: params.maxTicksLimit,
            autoSkip: false,
            callback: function(index, val) {
              return this.getLabelForValue(val)%params.lenOffset === 0 ? this.getLabelForValue(val) : '';
            }
          },
          title: {
            display: true,
            text: "[km]",
            color: "rgba(255,255,255,0.7)",
          }
        },
        y: {
          max: params.y_max + params.altitude.bottom,
          min: params.altitude.bottom,
          grid: {
              display: true,
              drawTicks: false,
              color: 'rgba(255,255,255,0.25)'
          },
          ticks: {
            color: 'rgba(255,255,255,0.7)',
            maxtickslimit: 5,
            callback: function(index) {
              // console.log("index", index);
              // console.log(index % (params.y_max/5));
              return index % 10 === 0 ? index : "";
            }
          },
          title: {
            display: true,
            text: "[m]",
            color: "rgba(255,255,255,0.7)",
          }
        },
      }
    },
  });
}

export function drawChart2 (containerId, width, height, data, params) {
  const context = getContext (containerId, width, height);
  /* 標高と高さの比較 */
  return new ChartJS(context, {
    type: "line",
    data: {
      datasets: [{
        data: data,
        borderColor: 'rgba(0,0,0,0)', //false
        // borderColor: 'blue',
        pointRadius: 0,
        fill: false,
      }],
      labels: params.xlabel_array,
      pointRadius: 1,
    },
    options: {
      plugins: {
        annotation: {
          clip: false,
          annotations: {
            line2: {
              type: 'line',
              yMin: params.altitude.top,
              yMax: params.altitude.top,
              borderColor: 'rgba(255,255,255,0.6)',
              borderWidth: 1,
              borderDash: [5,5]
            },
            line3: {
              type: 'line',
              yMin: params.altitude.bottom,
              yMax: params.altitude.bottom,
              borderColor: 'rgba(255,255,255,0.6)',
              borderWidth: 1,
              borderDash: [5,5]
            },
            // 高さの表記
            label1: {
              type: 'label',
              xValue: params.xlabel_array.length/2,
              yValue:params.altitude.top+getAltitude(params.altitude.total)/20,
              textStrokeWidth: 0.7,
              textStrokeColor: 'rgba(255, 229, 94, 1)',
              color: 'rgba(255, 229, 94, 1)',
              backgroundColor: 'rgba(245,245,245,0)',
              content: ["　Height Difference                     " + "   m"],
              font: {
                size: 14, 
                // family: "hiragino"
              },
            },
            label2: {
              type: 'label',
              xValue: params.xlabel_array.length/2,
              yValue:params.altitude.top+getAltitude(params.altitude.total)/15,
              textStrokeWidth: 2.6,
              textStrokeColor: 'rgba(255, 229, 94, 1)',
              color: 'rgba(255, 229, 94, 1)',
              backgroundColor: 'rgba(245,245,245,0)',
              content: ["              "+ params.altitude.total.toFixed(0) + ""],
              font: {
                size: 30, 
                font: "Roboto-Black"
              },
            }, 
            box1: {
              type: 'box',
              xMin: params.xlabel_array[((params.xlabel_array.length-1)/10*4.6).toFixed(0)],
              xMax: params.xlabel_array[((params.xlabel_array.length-1)/10*5.5).toFixed(0)],
              yMin: params.altitude.top,
              yMax: params.altitude.bottom,
              borderColor: 'rgba(255, 229, 94,1)',
              borderWidth: 1,
              backgroundColor: 'rgba(255, 229, 94, 0.3)',
            },
          }
        },
        title: {
          display: true,
          text: ["", ""],
          font: {
            size: 14
          }
        },
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          grid: {
            display: true,
            drawTicks: false,
            color: 'rgba(0,0,0,0)'
          },
          ticks: {
            color: 'rgba(0,0,0,0)',
            // color:"blue",
            maxTicksLimit: params.maxTicksLimit,
            autoSkip: false,
            callback: function(index, val) {
              return this.getLabelForValue(val)%params.lenOffset === 0 ? this.getLabelForValue(val) : '';
            }
          },
          title: {
            display: true,
            text: "[km]",
            color: "rgba(255,255,255,0)",
          }
        },
        y: {
          max: params.y_max + params.altitude.bottom,
          min: params.altitude.bottom,
          grid: {
            display: true,
            drawTicks: false,
            color: 'rgba(0,0,0,0)'
          },
          ticks: {
            color: 'rgba(0,0,0,0)',
            // color:"blue",
            maxtickslimit: 5,
            callback: function(index) {
              // console.log("index", index);
              // console.log(index % (params.y_max/5));
              return index % 10 === 0 ? index : "";
            }
          },
          title: {
            display: true,
            text: "[m]",
            color: "rgba(255,255,255,0)",
          }
        }
      }
    },
    plugins: [getPlugin(params)],
  });
}

export function drawChart3 (containerId, width, height, data, params) { 
  let plusAlpha = "";
  if ((params.baseDistance/42.295).toFixed(1) < 10) { 
    plusAlpha = "";
  } else if ((params.baseDistance/42.295-10).toFixed(1) < 10) { 
    plusAlpha = "                                                        +"+(params.baseDistance/42.295-10).toFixed(1);
  } else {
    plusAlpha = "                                                  +"+(params.baseDistance/42.295-10).toFixed(1);
  }
  const context = getContext (containerId, width, height);
  /* 距離をフルマラソンで比較 */
  return new ChartJS(context, {
    type: "line",
    data: {
      datasets: [{
        data: data,
        borderColor: 'rgba(0,0,0,0)', //false
        // borderColor: 'red',
        pointRadius: 0,
        fill: false,
      }],
      labels: params.xlabel_array,
      pointRadius: 1,
    },
    options: {
      plugins: {
        annotation: {
          annotations: {
            line2: {
              type: 'line',
              xMin: 0,
              xMax: 0,
              borderColor: 'rgba(255,255,255,0.6)',
              borderWidth: 2,
              borderDash: [4,4]
            },
            line3: {
              type: 'line',
              xMin: params.baseDistance.toFixed(2),
              xMax: params.baseDistance.toFixed(2),
              borderColor: 'rgba(255,255,255,0.6)',
              borderWidth: 2,
              borderDash: [4,4]
            },
            label1: {
              type: 'label',
              xValue: params.xlabel_array.length/2,
              yValue: getAltitude(params.altitude.total)/2 + params.altitude.bottom+params.y_max/9.3, 
              textStrokeWidth: 0.7,
              textStrokeColor: 'rgba(255, 229, 94, 1)',
              color: 'rgba(255, 229, 94, 1)',
              backgroundColor: 'rgba(245,245,245, 0)',
              content: ["Distance       　      "+ "   " + "     km "],
              font: {
                size: 14
              },
            },
            label2: {
                type: 'label',
                xValue: params.xlabel_array.length/2,
                yValue: getAltitude(params.altitude.total)/2 + params.altitude.bottom+params.y_max/8, 
                textStrokeWidth: 2.6,
                textStrokeColor: 'rgba(255, 229, 94, 1)',
                color: 'rgba(255, 229, 94, 1)',
                backgroundColor: 'rgba(245,245,245,0)',
                content: ["     "+ params.baseDistance.toFixed(0) + " "],
                font: {
                  size: 30,
                  font: "Roboto-Black"
                },
              }, 
            box2: {
              type: 'box',
              xMin: params.xlabel_array[0],
              xMax: params.baseDistance.toFixed(2),
              yMin: getAltitude(params.altitude.total)/2 + params.altitude.bottom+params.y_max/21,
              yMax: getAltitude(params.altitude.total)/2 + params.altitude.bottom-params.y_max/21,
              borderColor: 'rgba(255, 229, 94,1)',
              borderWidth: 1,
              backgroundColor: 'rgba(255, 229, 94, 0.3)',
            },
          }
        },
        title: {
          display: true,
          text: [
            "", 
            "　Distance of marathon x "+(params.baseDistance/42.295).toFixed(1)+" approx.",
            "","",
            plusAlpha,
          ],
          color: "white",
          font: {
            size: 15
          }
        },
        legend: {
          display: false
        },
        customCanvasBackgroundColor: {
          color: 'lightGreen',
        }
      },
      scales: {
        x: {
          grid: {
            display: true,
            drawTicks: false,
            // color: "blue",
            color: 'rgba(0,0,0,0)',
          }, 
          ticks: {
            // display: false,
            color: 'rgba(0,0,0,0)',
            // color: "blue",
            maxTicksLimit: params.maxTicksLimit,
            autoSkip: false,
            callback: function(index, val) {
              return this.getLabelForValue(val)%params.lenOffset === 0 ? this.getLabelForValue(val) : '';
            }
          },
          title: {
            display: true,
            text: "[km]",
            color: "rgba(255,255,255,0)",
          }
        },
        y: {
          max: params.y_max + params.altitude.bottom,
          min: params.altitude.bottom,
          grid: {
            display: true,
            drawTicks: false,
            // color: "blue",
            color: 'rgba(0,0,0,0)',
          },
          ticks: {
            // display: false,
            color: 'rgba(0,0,0,0)',
            // color: "blue",
            maxtickslimit: 5,
            callback: function(index) {
              // console.log("index", index);
              // console.log(index % (params.y_max/5));
              return index % 10 === 0 ? index : "";
            }
          },
          title: {
            display: true,
            text: "[m]",
            color: "rgba(255,255,255,0)",
          }
        }
      },
    },
  });
}

export function drawChart4 (containerId, width, height, data, params) {
  // const canvasEl = document.createElement("canvas");
  // canvasEl.width = width;
  // canvasEl.height = height;
  // const containerEl = document.getElementById(containerId);
  // containerEl.childNodes.forEach( child => containerEl.removeChild(child));
  // containerEl.appendChild(canvasEl);
  const context = getContext (containerId, width, height);
  /** グラフ本体の描画 */
  return new ChartJS(context, {
    type: "line",
    data: {
      datasets: [{
        data: data,
        borderColor: 'rgba(255,255,255,0.7)',
        pointRadius: 0,
      }],
      labels: params.xlabel_array,
      pointRadius: 1,
    },
    options: {
      plugins: {
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 0,
              yMax: 0,
              borderColor: 'rgba(255,255,255,0.7)',
              borderWidth: 1,
            }
          }
        },
        title: {
          display: true,
          text: ["", ""],
          font: {
            size: 14
          }
        },
        legend: {
          display: false
        },
        // tooltip: {
        //   callbacks: {
        //       label: function(context) {
        //         console.log(context);
        //         let label = context.dataset.label || '';

        //         if (label) {
        //             label += ': ';
        //         }
        //         if (context.parsed.y !== null) {
        //             label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
        //         }
        //         return label;
        //       }
        //   }
        // }
      },
      scales: {
        x: {
          grid: {
            display: true,
            drawTicks: false,
            // color: "red",
            color: 'rgba(0,0,0,0)',
            // drawOnChartArea: true,
          },
          ticks: {
            color: 'rgba(0,0,0,0)',
            // color: "red",
            maxTicksLimit: params.maxTicksLimit,
            autoSkip: false,
            callback: function(index, val) {
              return this.getLabelForValue(val)%params.lenOffset === 0 ? this.getLabelForValue(val) : '';
            }
          },
          title: {
            display: true,
            text: "[km]",
            color: "rgba(255,255,255,0)",
          }
        },
        y: {
          max: params.y_max + params.altitude.bottom,
          min: params.altitude.bottom,
          grid: {
              // display: false,
            display: true,
            drawTicks: false,
            color: 'rgba(0,0,0,0)',
            // color: 'rgba(0,0,0,1)',
          },
          ticks: {
            color: 'rgba(255,255,255,0)',
            // color: "red",
            maxtickslimit: 5,
            // callback: function(value) {
            //   // console.log("index", index);
            //   // console.log(index % (params.y_max/5));
            //   return getLabelForValue(value) == 0 ? getLabelForValue(value)+"m" : "";
            // }
            callback: function(index) {
              // console.log("index", index);
              // console.log(index % (params.y_max/5));
              return index % 10 === 0 ? index: "";
            }
          }, 
          title: {
            display: true,
            text: "[m]",
            color: "rgba(255,255,255,0)",
          }
        },
      }
    },
  });
}

export function setFullMarathon(params){
  let fullMarathonCount = (params.baseDistance/42.295).toFixed(1)
  // console.log("full marathon count", fullMarathonCount);
  if (fullMarathonCount >= 10){
    document.getElementById("rate2").style.width = "100%";
  } else {
    document.getElementById("rate2").style.width = fullMarathonCount*10+"%";
  }
//   else if(fullMarathonCount >= 9){
//     document.getElementById("rate2").style.width = fullMarathonCount*10+"%";
//   } else if(fullMarathonCount <= 3){
//     document.getElementById("rate2").style.width = fullMarathonCount*10+"%";
//   } else {
//     document.getElementById("rate2").style.width = fullMarathonCount*10+"%";
//   }
}

function getContext (containerId, width, height) {
  const canvasEl = document.createElement("canvas");
  canvasEl.width = width;
  canvasEl.height = height;
  const containerEl = document.getElementById(containerId);
  containerEl.childNodes.forEach( child => containerEl.removeChild(child));
  containerEl.appendChild(canvasEl);
  const context = canvasEl.getContext("2d");
  return context;
}

function getPlugin (params){
  // imageをセットして、標高の最大値を返す。
  let altitude_total = params.altitude.total;
  let backgroundImageArray = [
    '東京_修正版.png'+'?'+Date.now(),
    '富士山_修正版.png'+'?'+Date.now(),
    'チョモランマ_修正版.png'+'?'+Date.now(),
    '旅客機_修正版.png'+'?'+Date.now(),
  ];

  //TODO 透明度の調整
  const image = new Image();
  image.src = backgroundImageArray[0]; // default settings
  // console.log("altitude_total;", altitude_total);
  let x = 0;
  let y = 43; // position of the image
  let opacity = 0;
  if (altitude_total <= 1000){
    params.baseDistance < 100 ? y = 43+16 : y = 40+14;
    x = 3;
    image.src = backgroundImageArray[0];
    opacity = 0.4;
  } else if (altitude_total > 1000 && altitude_total <= 5000){
    image.src = backgroundImageArray[1];
    x = 3;
    params.baseDistance < 100 ? y = 58 : y = 51;
    opacity = 0.4;
  } else if (altitude_total > 5000 && altitude_total <= 10000){
    image.src = backgroundImageArray[2];
    x = 3;
    if (params.baseDistance < 100 ){
      y = 41 + 18;
    } else if (params.baseDistance < 1000 ) {
      y = 34+19;
    } else {
      y = 34+15;
    }
    opacity = 0.4;
  } else {
    image.src = backgroundImageArray[3];
    y = 42+8;
    opacity = 0.45;
  }

  const plugin = { 
    id: 'customCanvasBackgroundImage',
    beforeDraw: (chart) => {
      const {ctx} = chart;
      ctx.globalCompositeOperation = 'source-over';
      let width = 240*1.357;
      let height = 240;
      ctx.globalAlpha = opacity;
      ctx.drawImage(image, x, y, width, height);
      ctx.globalAlpha = 1;
    }
  };

  return plugin;
}