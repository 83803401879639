<template>
  <div class="switchable-svg-icon-button" @click="click">
    <div class="icon-image" v-show="isOn">
      <img :src="`/img/icons/toolbar/${onSvgName}`" :alt="onImgAlt" />
    </div>
    <div class="icon-image" v-show="!isOn">
      <img :src="`/img/icons/toolbar/${offSvgName}`" :alt="offImgAlt" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchableSvbIconButton",
  props: {
    isOn: {
      type: Boolean,
      required: true,
    },
    /**
     * /public/img/icons/toolbar/下にあるsvgのファイル名を指定する
     */
    onSvgName: {
      type: String,
      required: true,
    },
    /**
     * /public/img/icons/toolbar/下にあるsvgのファイル名を指定する
     */
    offSvgName: {
      type: String,
      required: true,
    },
    /**
     * On時のアイコンが表示できなかった際のalt文字列を指定する
     */
    onImgAlt: {
      type: String,
      default: "",
    },
    /**
     * Off時のアイコンが表示できなかった際のalt文字列を指定する
     */
    offImgAlt: {
      type: String,
      default: "",
    },
  },
  methods: {
    /**
     * click時のイベントを送信する
     */
    click() {
      this.$emit("on-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.switchable-svg-icon-button {
  .icon-image {
    width: 25px;
    height: 25px;
    display: block;
    text-align: center;
    img {
      height: 100%;
      // alt文字列の色を指定
      color: #fff;
    }
  }
}
</style>
