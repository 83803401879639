<template>
  <div class="toolbar">
    <div class="operation-buttons">
      <!-- 地図ドラッグ機能 -->
      <SwitchableSvbIconButton
        v-tooltip="createTooltipConfig('Hand Tool')"
        class="operation-button"
        :is-on="selectedOperation === MAP_OPERATIONS.MOVE"
        on-svg-name="icon-move.svg"
        off-svg-name="icon-move-na.svg"
        on-img-alt="地図ドラッグOn"
        off-img-alt="地図ドラッグOff"
        @on-click="onChangeOperation(MAP_OPERATIONS.MOVE)"
      />
      <!-- 距離計測機能 -->
      <SwitchableSvbIconButton
        v-tooltip="createTooltipConfig('Distance Measure Tool')"
        class="operation-button"
        :is-on="selectedOperation === MAP_OPERATIONS.RULER"
        on-svg-name="icon-polyline.svg"
        off-svg-name="icon-polyline-na.svg"
        on-img-alt="距離測定On"
        off-img-alt="距離測定Off"
        @on-click="onChangeOperation(MAP_OPERATIONS.RULER)"
      />
      <!-- 面積計測機能 -->
      <SwitchableSvbIconButton
        v-tooltip="createTooltipConfig('Area Measure Tool')"
        class="operation-button"
        :is-on="selectedOperation === MAP_OPERATIONS.AREA"
        on-svg-name="icon-polygon.svg"
        off-svg-name="icon-polygon-na.svg"
        on-img-alt="面積計算On"
        off-img-alt="面積計算Off"
        @click="onChangeOperation(MAP_OPERATIONS.AREA)"
      />
      <!-- マーカー設置機能 -->
      <SwitchableSvbIconButton
        v-tooltip="createTooltipConfig('Marker Tool')"
        class="operation-button"
        :is-on="selectedOperation === MAP_OPERATIONS.MARKER"
        on-svg-name="tool-icon-pin.svg"
        off-svg-name="tool-icon-pin-na.svg"
        on-img-alt="マーカー設置On"
        off-img-alt="マーカー設置Off"
        @on-click="onChangeOperation(MAP_OPERATIONS.MARKER)"
      />
      <!-- 標高計測機能 -->
      <SwitchableSvbIconButton
        v-tooltip="createTooltipConfig('Altitude Measure and visualize Tool')"
        class="operation-button beta-label"
        :is-on="selectedOperation === MAP_OPERATIONS.ALTITUDE"
        on-svg-name="icon-altitude3.svg"
        off-svg-name="icon-altitude-na3.svg"
        on-img-alt="標高測定On"
        off-img-alt="標高測定Off"
        @on-click="onChangeOperation(MAP_OPERATIONS.ALTITUDE)"
      />
    </div>
    <div class="mode-buttons">
      <!-- 月食機能 -->
      <SwitchableSvbIconButton
        class="mode-button"
        v-tooltip="
          createTooltipConfig(
            'Eclipse tool: Learn about the lunar eclipse on November 8, 2022.'
          )
        "
        :is-on="selectedMode === MAP_MODE.ECLIPSE"
        on-svg-name="eclipse-moon.svg"
        off-svg-name="eclipse-moon-na.svg"
        on-img-alt="月食観察機能On"
        off-img-alt="月食観察機能Off"
        @on-click="onChangeMode(MAP_MODE.ECLIPSE)"
      />
    </div>
  </div>
</template>

<script setup>
import SwitchableSvbIconButton from "./Common/SwitchableSvbIconButton.vue";
import { defineProps, defineEmits, toRefs } from "vue";
import COMMON_CONST from "../js/common-const.js";

const MAP_OPERATIONS = COMMON_CONST.MAP_OPERATIONS;
const MAP_MODE = COMMON_CONST.MAP_MODE;

const props = defineProps({ selectedOperation: String, selectedMode: String });
const { selectedOperation, selectedMode } = toRefs(props);
const emit = defineEmits(["on-change-operation", "on-change-mode"]);
const onChangeOperation = (operation) => {
  emit("on-change-operation", operation);
};

const onChangeMode = (operation) => {
  emit("on-change-mode", operation);
};

const createTooltipConfig = (text) => {
  const config = {
    content: `<span style=font-size:12px;>${text}</span>`,
    html: true,
    placement: "bottom",
  };
  return config;
};
</script>

<style lang="scss" scoped>
// TODO 標高機能の画像切り替え
@import "@/style/globalStyle.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap'); // medium


.toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 8px;
  left: 8px;
  height: 40px;
  background: #01030ae2;
  .operation-buttons {
    display: flex;
    border-right: 1px solid #aaaaaa;
    .operation-button {
      margin: 6px;
      @include sp {
        margin: 0px;
        padding: 0 20px;
      }
      &:first-child {
        margin-left: 15px;
        @include sp {
          margin: 0px;
        }
      }
      &:last-child {
        margin-right: 15px;
        @include sp {
          margin: 0px;
        }
      }
    }
  }
  .mode-buttons {
    display: flex;
    .mode-button {
      margin: 6px;
      @include sp {
        margin: 0px;
        padding: 0 20px;
      }
      &:first-child {
        margin-left: 15px;
        @include sp {
          margin: 0px;
        }
      }
      &:last-child {
        margin-right: 15px;
        @include sp {
          margin: 0px;
        }
      }
    }
  }
  .beta-label {
    position: relative;
    &::after {
      content: "NEW";
      font-size: 8px;
      font-family: 'Roboto', sans-serif;
      text-align:center;
      display: block;
      background: rgb(255,229,94);
      color: rgb(11,12,25);
      letter-spacing: 0.2px;
      border-radius: 7px;
      width: 31px;
      height: 13px;
      left: -4%;
      line-height: 13px;
      position: absolute;
      bottom: -70%;
      @include sp {
        left: 29%;
      }
    }
  }
}
</style>
