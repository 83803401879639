import { onMounted, ref, createApp } from "vue";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import COMMON_CONST from "../js/common-const.js";
import PolygonPopupContents from "./MapboxPopupContents/PolygonPopupContents.vue";
import LinePopupContents from "./MapboxPopupContents/LinePopupContents";
import GraphPopupContents from "./MapboxPopupContents/GraphPopupContents"
import {
  getDrawStyles,
  getPolygonStyles,
  getLineStyles,
} from "../js/layer-styles.js";

const MAP_OPERATIONS = COMMON_CONST.MAP_OPERATIONS;
let makerObjList = [];
let lineMode = 0;

export const useOperation = (map) => {
  const shapeColor = "#FFE55E"; // moon yellow
  const selectedOperation = ref("");
  const draw = new MapboxDraw({
    keybindings: false,
    displayControlsDefault: false,
    styles: getDrawStyles(shapeColor),
    // Select which mapbox-gl-draw control buttons to add to the map.
  });

  const changeOperation = (operation) => {
    if (selectedOperation.value === operation) return;

    onSwitchFunction[selectedOperation.value].off();
    onSwitchFunction[operation].on();

    selectedOperation.value = operation;
  };

  // 移動モードに変更した時の動作
  const switchMoveMode = {
    on: () => {
      map.value.getCanvas().style.cursor =
        toolDefaultCursor[MAP_OPERATIONS.MOVE];
      map.value.on("click", onClickFucntionAtMove);
    },
    off: () => {
      map.value.off("click", onClickFucntionAtMove);
    },
  };

  // 測量モードに変更した時の動作
  const switchRulerMode = {
    on: () => {
      lineMode = 1;
      map.value.getCanvas().style.cursor =
        toolDefaultCursor[MAP_OPERATIONS.RULER];
      map.value.addControl(draw);
      draw.changeMode(draw.modes.DRAW_LINE_STRING);
    },
    off: () => {
      lineMode = 1;
      const { features } = draw.getAll();
      for (let i = 0; i < features.length - 1; i++) {
        saveLineFeature(features[i], features[i].id);
      }
      map.value.removeControl(draw);
    },
  };

  // 面積計算を変更した時の動作
  const switchAreaMode = {
    on: () => {
      map.value.getCanvas().style.cursor =
        toolDefaultCursor[MAP_OPERATIONS.AREA];
      map.value.addControl(draw);
      draw.changeMode(draw.modes.DRAW_POLYGON);
    },
    off: () => {
      const { features } = draw.getAll();
      for (let i = 0; i < features.length - 1; i++) {
        savePolygonFeature(features[i], features[i].id);
      }
      map.value.removeControl(draw);
    },
  };

  // マーカーモードを変更した時の動作
  const switchMarkerMode = {
    on: () => {
      map.value.getCanvas().style.cursor =
        toolDefaultCursor[MAP_OPERATIONS.MARKER];
      map.value.on("click", addMarker);
    },
    off: () => {
      map.value.off("click", addMarker);
    },
  };
  
  // 標高計算モード
  const switchAltitudeMode = {
    on: () => {
      lineMode = 0;
      map.value.getCanvas().style.cursor =
        toolDefaultCursor[MAP_OPERATIONS.ALTITUDE];
      map.value.addControl(draw);
      draw.changeMode(draw.modes.DRAW_LINE_STRING);
    },
    off: () => {
      lineMode = 0;
      // console.log(draw.getAll());
      const { features } = draw.getAll();
      for (let i = 0; i < features.length - 1; i++) {
        // createAltitudeArray(features[i].geometry.coordinates)
        saveAltitudeLineFeature(features[i], features[i].id);
      }
      map.value.removeControl(draw);
    },
  };
  
  const savePolygonFeature = (feature, id) => {
    map.value.addSource(id, {
      type: "geojson",
      data: feature,
    });

    const fillId = `fill_${id}`;
    const lineId = `line_${id}`;

    const { fill, line } = getPolygonStyles(shapeColor, id, fillId, lineId);

    map.value.addLayer(fill);
    map.value.addLayer(line);

    setCursorChangerToLayer(fillId);
  };

  const saveLineFeature = (feature, id) => {
    map.value.addSource(id, {
      type: "geojson",
      data: feature,
    });

    const lineId = `line_${id}`;

    const { line } = getLineStyles(shapeColor, id, lineId);

    map.value.addLayer(line);

    setCursorChangerToLayer(lineId);
  };

  const saveAltitudeLineFeature = (feature, id) => {
    map.value.addSource(id, {
      type: "geojson",
      data: feature,
    });

    const lineId = `altitude_line_${id}`;

    const { line } = getLineStyles(shapeColor, id, lineId);

    map.value.addLayer(line);

    setCursorChangerToLayer(lineId);
  };

  const setCursorChangerToLayer = (layerId) => {
    map.value.on("mouseenter", layerId, () => {
      map.value.getCanvas().style.cursor = "pointer";
    });

    map.value.on("mouseleave", layerId, () => {
      map.value.getCanvas().style.cursor =
        toolDefaultCursor[selectedOperation.value];
    });
  };

  const onSwitchFunction = {
    [MAP_OPERATIONS.MARKER]: switchMarkerMode,
    [MAP_OPERATIONS.MOVE]: switchMoveMode,
    [MAP_OPERATIONS.RULER]: switchRulerMode,
    [MAP_OPERATIONS.AREA]: switchAreaMode,
    [MAP_OPERATIONS.ALTITUDE]: switchAltitudeMode,
  };

  const toolDefaultCursor = {
    [MAP_OPERATIONS.MARKER]: "pointer",
    [MAP_OPERATIONS.MOVE]: "grab",
    [MAP_OPERATIONS.RULER]: "crosshair",
    [MAP_OPERATIONS.AREA]: "crosshair",
    [MAP_OPERATIONS.ALTITUDE]: "crosshair",
  };

  const initToolbar = () => {
    selectedOperation.value = MAP_OPERATIONS.MOVE;
    switchMoveMode.on();
  };

  onMounted(() => {
    initToolbar();

    map.value.on("load", () => {
      map.value.on("draw.modechange", function (e) {
        if (
          e.mode === draw.modes.SIMPLE_SELECT &&
          selectedOperation.value === MAP_OPERATIONS.AREA
        ) {
          draw.changeMode(draw.modes.DRAW_POLYGON);
        } else if (
          e.mode === draw.modes.SIMPLE_SELECT &&
          selectedOperation.value === MAP_OPERATIONS.RULER
        ) {
          draw.changeMode(draw.modes.DRAW_LINE_STRING);
        } else if (
          e.mode === draw.modes.SIMPLE_SELECT &&
          selectedOperation.value === MAP_OPERATIONS.ALTITUDE
        ) {
          draw.changeMode(draw.modes.DRAW_LINE_STRING);
        }
      });
    });
  });

  function createTwBtn(shareUrl) {
    var text = "Enjoy Lunar Eclipse!\n";
    var src = "https://platform.twitter.com/widgets/tweet_button.html";
    src += "?lang=en";
    src += "&size=medium";
    src += "&text=" + encodeURIComponent(text);
    src += "&related=aaa";
    src += "&url=" + encodeURIComponent(shareUrl);
    src += "&hashtags=CompassMOON";
    return src;
  }

  //TODO2
  // async function readCOG(lngLatArray, xOffset, yOffset)  {
  //   const tiff = await fromUrl ( filepath ); 
  //   for (let i = 0; i < 100; i++) { 
  //     const pixel_coordinate_x = 46080/180 * lngLatArray[0][0]+xOffset*i;
  //     const pixel_coordinate_y = 46080/180 * lngLatArray[0][1]+yOffset*i;
  //     // console.log("ピクセル座標 (x, y): (", pixel_coordinate_x, ", ", pixel_coordinate_y, ")");
      
  //     /* getImageあたりの中身
  //     const image = await tiff.getImage ();  
  //     const pyramidNum = await tiff.getImageCount();
  //     console.log(`Pyramid: ${pyramidNum}`, );
  //     console.log(`Bounding box: ${image.getBoundingBox()}`);
  //     console.log(`Origin: ${image.getOrigin()}`);
  //     console.log(`Resolution: ${image.getResolution()}`); // 1ピクセルあたりの分解能
  //     console.log(`Size: ${image.getWidth()}, ${image.getHeight()}`); // 画像のpixelサイズ
  //     console.log(`Tile size: ${image.getTileWidth()}, ${image.getTileHeight()}`); // COG内部のタイルサイズ
  //     console.log(`Band: ${image.getSamplesPerPixel()}`); // 波長などのデータの種類(1はモノクロ、RGBは3)
  //     */
  //     // boundary is between [-resolution*46080, -resolution*23040, resolution*46080, resolution*23040]
  //     const image2 = await tiff.readRasters({
  //       bbox: [
  //         RESOLUTION*pixel_coordinate_x, 
  //         RESOLUTION*pixel_coordinate_y, 
  //         RESOLUTION*(pixel_coordinate_x+1), 
  //         RESOLUTION*(pixel_coordinate_y+1)
  //         ],
  //       // resX: 100, // 使い方わからない
  //       // resY: 100 // 使い方わからない
  //     })
  //     // const altitude = image2[0][0];
  //     // console.log(altitude);
  //     altitudeArray[altitudeArray.length] = image2[0][0];
  //   }
  //     return altitudeArray
  // }
    
  //TODO1 
  // function createAltitudeArray(lngLatArray) {
  //   console.log("createAltitudeArray function called with: ", lngLatArray)
  //   // let altitudeArray = []; // 標高データの配列
  //   let xOffset = (lngLatArray[lngLatArray.length-1][0] - lngLatArray[0][0]) / DEVIDE;
  //   let yOffset = (lngLatArray[lngLatArray.length-1][1] - lngLatArray[0][1]) / DEVIDE;
  //   console.log("xOffset", xOffset);
  //   console.log("yOffset", yOffset);

   
  //   readCOG(lngLatArray, xOffset, yOffset)
  //     .then(
  //       (data) => {
  //         console.log(data);
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  //   console.log("-------------------");
  // }

  const addMarker = (event) => {
    if (makerObjList.length != 0) {
      makerObjList[0].remove(); // すでにあるマーカー削除
      makerObjList.pop();
    }
    const coordinates = event.lngLat;
    var lat = coordinates["lat"];
    var lng = coordinates["lng"];
    console.log(lat, lng);

    const removeFunction = (removeId) => {
      // layer削除
      const layers = map.value.getStyle().layers;

      const layerIds = layers.map((layer) => layer.id);

      const filtered = layerIds.filter((id) => id.includes(removeId, 0));
      filtered.forEach((id) => {
        map.value.removeLayer(id);
      });
      // source削除
      map.value.removeSource(removeId);
    };

    const URL = "https://moon.compass.tenchijin.co.jp?lng="; //prod
    // const URL = "http://localhost:8080?lng="; // dev
    // create the popup
    const popup = new mapboxgl.Popup({
      offset: 25,
      className: "sns-popup",
    }).setHTML(
      '<center><div><p>この場所をシェア！</p><p>Share this location!</p></div></center><center><iframe allowtransparency="true" frameborder="0" scrolling="no" src="' +
        createTwBtn(URL + lng + "&lat=" + lat) +
        '" style="width:80px; height:20px;" charset="utf-8"></iframe></center> <dispose-button color="rgba(255,0,0,1)" class="dispose-button" @click="removeFunction"/>'
    );
    const el = document.createElement("div");
    el.className = "marker";
    const width = 65;
    const height = 58;
    el.style.backgroundImage = "url(marker.png)";
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.top = `5px`;
    el.style.left = `20px`;
    el.style.backgroundSize = "100%";

    // create the marker
    var makerObj = new mapboxgl.Marker(el)
      .setLngLat(coordinates)
      .addTo(map.value)
      .setPopup(popup)
      .togglePopup();
    makerObjList.push(makerObj);
  };

  const onClickFucntionAtMove = (e) => {
    const features = map.value.queryRenderedFeatures(e.point);
    if (features.length) addShapePopup(e.lngLat.lng, e.lngLat.lat, features[0]);
  };

  const addShapePopup = (lng, lat, feature) => {
    createShapePopup(lng, lat, feature).addTo(map.value);
  };

  const createShapePopup = (lng, lat, feature) => {
    const removeFunction = (removeId) => {
      // layer削除
      const layers = map.value.getStyle().layers;

      const layerIds = layers.map((layer) => layer.id);

      const filtered = layerIds.filter((id) => id.includes(removeId, 0));
      filtered.forEach((id) => {
        map.value.removeLayer(id);
      });
      // source削除
      map.value.removeSource(removeId);
    };

    const source = map.value.getSource(feature.layer.source);
    let popup = null;

    const component =
      feature.layer.metadata.drawedBy === draw.modes.DRAW_POLYGON ? PolygonPopupContents
        : (feature.layer.metadata.drawedBy === draw.modes.DRAW_LINE_STRING && lineMode===1) ? LinePopupContents
        : GraphPopupContents;

    const popupInstance = createApp(component, {
      feature: source,
      removeFunction: () => {
        removeFunction(source.id);
        popup.remove();
      },
    });

    const el = document.createElement("div");
    popupInstance.mount(el);

    popup = new mapboxgl.Popup({ className: "shape-popup", maxWidth: "350px" })
      .setLngLat([lng, lat])
      .setDOMContent(el);

    return popup;
  };

  return { selectedOperation, changeOperation };
};
