<template>
  <div class="main-area"><main-map class="main-map"></main-map></div>
</template>

<script>
import MainMap from "./components/MainMap.vue";

export default {
  name: "App",
  components: {
    MainMap,
  },
};
</script>

<style>
html,
body {
  height: 100%;
}
#app {
  height: 100%;
}
.main-area {
  position: absolute;
  top: 8px;
  left: 8px;
  margin: 0;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  width: -webkit-calc(100% - 16px);
  height: -webkit-calc(100% - 16px);
}
</style>
